<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex justify-center align-center h-full pa-16">
              <v-img
                contain
                max-width="60%"
                :src="require('@/assets/images//illustrations/forgot-password.png')"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Forgot Password? 🔒
                  </p>
                  <p class="mb-2">
                    Enter your email and we'll send you instructions to reset your password
                  </p>
                </v-card-text>

                <!-- success message -->
                <v-card-text v-if="successMessage">
                  <v-alert
                    text
                    color="primary"
                  >
                    <small class="d-block mb-1">
                      {{ successMessage }}
                    </small>
                  </v-alert>
                </v-card-text>

                <!-- request reset form -->
                <v-card-text v-else>
                  <v-form
                    ref="resetRequestForm"
                    @submit.prevent="requestPasswordChange"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-btn
                      block
                      type="submit"
                      color="primary"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Send reset link
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref, inject } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { axios } from '@axios'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Initializers
    const resetRequestForm = ref(null)
    const snackbarService = inject('snackbarService')

    // Properties
    const isPasswordVisible = ref(false)
    const email = ref(null)
    const successMessage = ref(null)
    const isLoading = ref(false)

    // Methods
    const requestPasswordChange = () => {
      isLoading.value = true
      const isFormValid = resetRequestForm.value.validate()

      if (!isFormValid) {
        isLoading.value = false

        return
      }

      axios
        .post('/auth/password', { email: email.value, redirect_url: `${window.location.origin}/reset-password` })
        .then(response => {
          isLoading.value = false
          successMessage.value = response.data.message
        })
        .catch(error => {
          isLoading.value = false
          snackbarService.error(error.response.data.errors[0] || 'Something went wrong. Please refresh!')
        })
    }

    return {
      // Initializers
      resetRequestForm,

      // Properties
      isPasswordVisible,
      email,
      successMessage,
      isLoading,

      // Others
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiChevronLeft,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Methods
      requestPasswordChange,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
